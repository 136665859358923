// we have problems with older browsers with some array functionality
// eslint-disable-next-line simple-import-sort/imports
import 'core-js/es/array';

/*
/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import '@angular/localize/init';
import 'zone.js';  // Included with Angular CLI.

// Add global to window, assigning the value of window itself
// This is required by twilio
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
(window as any).global = window;
